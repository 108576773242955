/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetServerSideProps } from 'next';

import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import destinationSignatures from '../../signatures/operationSignaturesDestinations.json';
import { getSubDirectoryLocale } from '@marriott/mi-leisure-components/utils';
import { PARAM_PAGENO } from '@marriott/mi-leisure-components/constants';
import { NEXT_PUBLIC_ENV_KEYS } from '@marriott/mi-leisure-components/constants';
import moment from 'moment';

const { REDIRECT_URL_DESTINATION } = process.env;
const { SESSION_APP_DOMAIN_URL } = process.env;

function pageRenderer(props) {
  console.log('[destination] props ', props);
  const url =
    props.model && props.model['canonical'] !== undefined && props.model['canonical'] !== ''
      ? new URL(props.model['canonical'], props.model['canonical'])
      : ''; // Base URL needed to parse relative URL
  const param = PARAM_PAGENO;
  if (url !== '' && url.searchParams.has(param)) {
    if (props.pageNo === '1') {
      url.searchParams.delete(param);
    } else {
      url.searchParams.set(param, props.pageNo); // Update the parameter value
    }
  } else {
    if (url !== '' && props.pageNo != null && props.pageNo !== '1') {
      url.searchParams.append(param, props.pageNo); // Add it if it doesn't exist
    }
  }
  const baseUrl = url.toString();
  props.model['canonical'] = baseUrl;
  return <PageRendererComponent {...props} />;
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const destinationSpecificData = async (context: any, parallelCallsResponse: any, interceptorResponse: any) => {
  console.log('[destinationSpecificData] offers context -', context);
  console.log('[destinationSpecificData page] parallelCallsResponse ', parallelCallsResponse);
  const currentLocale = interceptorResponse?.requestAttributes?.pageManagerRequest?.global?.localeKey?.replace(
    '_',
    '-'
  );
  const { req } = context;
  let pageNo = null;
  const isAuthorMode = req?.url?.includes('isAuthorMode');
  if (!isAuthorMode) {
    const url = new URL(req.url, `http://${req.headers.host}`); // Use the full URL
    //capturing query param pageNO from req url
    pageNo = url.searchParams.get(PARAM_PAGENO);
  }

  // Function to handle locale mapping, converting 'en-us' to 'en'
  const normalizeLocale = (locale: string) => (locale.includes('en-us') ? 'en' : locale);

  // Get the normalized locale and construct the redirect URL
  const normalizedLocale = normalizeLocale(getSubDirectoryLocale(currentLocale));
  const redirectUrlWithSubdirectory = `/${normalizedLocale}${REDIRECT_URL_DESTINATION}`;
  console.log('redirectUrlWithSubdirectory in phx destination handler ', redirectUrlWithSubdirectory);

  return {
    operationSignatures: destinationSignatures,
    redirectUrI: redirectUrlWithSubdirectory,
    pageNo: pageNo,
    nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS,
  };
};

const postAEMModelOperations = (context: any, parallelCallsResponse: any, interceptorResponse: any, model: any) => {
  console.log('[destinationSpecificData] interceptorResponse -', interceptorResponse);
  const { req } = context;
  const sessionID = req?.cookies?.sessionID ?? '';
  const { sessionData } = parallelCallsResponse;
  const isFlexibleDateSearch = false;
  // update sessionData utility server side
  const updateSessionObjfunc = async (ariesSearch: any = {}) => {
    try {
      const url = `${SESSION_APP_DOMAIN_URL}/phoenix-gateway/v1/updateSession`;
      const headers = {
        Cookie: `sessionID=${sessionID}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      };
      const payload = {
        AriesSearch: ariesSearch,
        remove: [],
      };
      await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      })
        .then(res => res.json())
        .then(data => {
          return data?.status;
        });
    } catch (error) {
      console.log(`[DESTINATIONS] update session API Failed:, ${error}`);
    }
  };
  const updateSessionData = async (destinationMetaData: any) => {
    const today = moment().utc().startOf('day').toISOString();
    const tomorrow = moment().utc().add(1, 'days').startOf('day').toISOString();
    let ariesSearch: any = sessionData?.cacheData?.data?.AriesSearch;
    ariesSearch = {
      ...ariesSearch,
      searchCriteria: {
        ...ariesSearch?.searchCriteria,
        address: {
          city:
            (!(destinationMetaData?.isCountryEnabled || destinationMetaData?.isStateEnabled)
              ? destinationMetaData?.city
              : '') || '',
          country: destinationMetaData?.countryCode || '',
          destination: destinationMetaData?.destinationName || '',
          latitude:
            (!(destinationMetaData?.isCountryEnabled || destinationMetaData?.isStateEnabled)
              ? parseFloat(destinationMetaData?.latitude)
              : '') || '',
          longitude:
            (!(destinationMetaData?.isCountryEnabled || destinationMetaData?.isStateEnabled)
              ? parseFloat(destinationMetaData?.longitude)
              : '') || '',
          stateProvince: (destinationMetaData?.isCountryEnabled ? '' : destinationMetaData?.stateCode) || '',
          stateProvinceDisplayName: (destinationMetaData?.isCountryEnabled ? '' : destinationMetaData?.stateCode) || '',
          destinationType: destinationMetaData?.isCountryEnabled
            ? 'NA'
            : destinationMetaData?.isStateEnabled
            ? 'State/Province/Region'
            : 'City',
        },
        countryName: destinationMetaData?.country || '',
        destinationAddressPlaceId: destinationMetaData?.placeId || '',
        geoCode: !(destinationMetaData?.isCountryEnabled || destinationMetaData?.isStateEnabled)
          ? `${destinationMetaData?.latitude}, ${destinationMetaData?.longitude}`
          : '',
        availabilityRequestVO: {
          ...ariesSearch?.searchCriteria?.availabilityRequestVO,
          checkInDate: today,
          checkOutDate: tomorrow,
          flexibleDate: isFlexibleDateSearch,
          lengthOfStay: 1,
          flexibleDateSearchRateDisplay: false,
          hideFlexibleDateCalendar: false,
          numAdultsPerRoom: 1,
          numChildrenPerRoom: 0,
          numGuestsPerRoom: 1,
          numRooms: 0,
        },
      },
      search_date_type: isFlexibleDateSearch ? 'Flexible Dates' : 'With Dates',
      search_dates_flexible: isFlexibleDateSearch,
    };
    await updateSessionObjfunc(ariesSearch);
  };
  updateSessionData(model?.destinationMetaData);
  const items = model.cqItems?.root?.[':items']?.responsivegrid?.[':items'];
  Object.keys(items).forEach(key => {
    const component = items[key];
    if (component[':type'] === 'mi-aem-leisure-spa/components/leisure-searchform') {
      component.numberOfSearchForms = 1;
      component.enableRedeemPoints = false;
      component.disableSpecialRates = true;
      component.disableRoomGuest = true;
    }
  });
  return model;
};
//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps<any> = prePageCall(
  destinationSpecificData,
  {},
  false,
  false,
  false,
  undefined,
  postAEMModelOperations
);
export default pageRenderer;
